import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Age from "./pages/age";
import Inspiring from "./pages/inspiring";
import Classisicm from "./pages/classicism"
import Artnov from "./pages/artnov";
import Home from "./pages/home1";
import Impressionism from "./pages/impressionism"
import Suprematism from "./pages/suprematism";
import { useOrientation } from "react-use";
import PortraitContent from "./pages/portrait_view";

function App() {
  // const { type } = useOrientation();


  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isTall, setIsTall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Вызываем функцию сразу, чтобы получить начальные размеры

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const aspectRatio = dimensions.height / dimensions.width;
    setIsTall(aspectRatio > 1.4);
  }, [dimensions]);


  return (
    <Router>
      {isTall ? (
        <Routes>
          <Route path="/" element={<Age />} />
          <Route path="/home" element={<Home />} />
          <Route path="/impressionism" element={<Impressionism />} />
          <Route path="/classicism" element={<Classisicm />} />
          <Route path="/artnouveau" element={<Artnov />} />
          <Route path="/suprematism" element={<Suprematism />} />
        </Routes>
      ) : (
        <PortraitContent />
      )}
    </Router>
  );
}

export default App;

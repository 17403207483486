import React, { useState, useRef, useEffect } from "react";
import backgroundImage from "../images/background.png";
import logo from "../images/logo.png";
import gif from "../images/01-Swipe left.gif";
import central from "../images/central.png"
import leftb from "../images/left.png";
import rightb from "../images/right.png";
import Card from "../Card"; 
import impressionism from "../images/Impressionism banner.png";
import suprematism from "../images/Suprematism banner.png";
import artnov from "../images/Art Nouveau banner.png";
import classicism from "../images/Classicism banner.png";
import VisibilitySensor from "react-visibility-sensor";
import IndicatorDots from './IndicatorDots'
import videoBackground from '../images/bgvideo.mp4';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useNavigate } from "react-router-dom";

function Home() {

  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [leftButtActive, setleftButtActive] = useState(true);
  const [rightButtActive, setrightButtActive] = useState(true);
  const [goDots, setGodots] = useState(false);
  const suprematismRef = useRef([]);
  const starttextref = useRef([]);
  const impresRef = useRef(null);
  const artnovRef = useRef(null);
  const classRef = useRef([]);
  
  const scrollContainerRef = useRef(null);

  // const [isExpanded1, setIsExpanded1] = useState(false);
  // const [isExpanded2, setIsExpanded2] = useState(false);
  // const [isExpanded3, setIsExpanded3] = useState(false);
  // const [isExpanded4, setIsExpanded4] = useState(false);

  const data = [
    { name: "IMPRESSIONISM", imageURL: impressionism , link:"/impressionism"},
    { name: "SUPREMATISM", imageURL: suprematism , link:"/suprematism"},
    { name: "ART NOUVEAU", imageURL: artnov , link:"/artnouveau"},
    { name: "CLASSICISM", imageURL: classicism , link:"/classicism"},
  ];

  const goMoveRight = ()=>{

    let targetIndex = activeCardIndex + 1
    setActiveCardIndex(targetIndex);

    console.log(targetIndex);

    if(targetIndex!==4){
      setleftButtActive(true)
    }else{
      setleftButtActive(false)
    }

    if(targetIndex!==1){
      setrightButtActive(true)
    }else{
      setrightButtActive(false)
    }
    
  
    switch (targetIndex) {
      case 1:
        impresRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      case 2:
        suprematismRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      case 3:
        artnovRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      case 4:
        classRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      default:
        break;
    }
  };

  const goMoveLeft = ()=>{

    let targetIndex = activeCardIndex - 1
    setActiveCardIndex(targetIndex);

    console.log(targetIndex);

    if(targetIndex!==1){
      setrightButtActive(true)
    }else{
      setrightButtActive(false)
    }

    if(targetIndex!==4){
      setleftButtActive(true)
    }else{
      setleftButtActive(false)
    }
    
  
    switch (targetIndex) {
      case 1:
        impresRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      case 2:
        suprematismRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      case 3:
        artnovRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      case 4:
        classRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        break;
      default:
        break;
    }
  };
  

  // const awesmDeb = AwesomeDebouncePromise(()=>{

  //   const targetIndex = activeCardIndex + 1;
  //   let techdiv = document.getElementById('ababa');
  //   let techScrollPos = techdiv.scrollLeft;

  //   let endPos = techdiv.scrollWidth;

  //   // alert(techdiv.scrollWidth);

  //   // console.log('targetIndex');
  //   // console.log(targetIndex);
  //   // console.log('techScrollPos');
  //   // console.log(techScrollPos);

  //   // alert('aaa');

  //   if(techScrollPos!=0){
  //     // alert(document.getElementById('ababa').scrollLeft);
  //     if(targetIndex>4){
  //       setActiveCardIndex(0);
  //       starttextref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  //       setTimeout(()=>{
  //         document.getElementById('ababa').scrollLeft = 0;
  //       },1000)
  //     }else{
  //       setActiveCardIndex(targetIndex);
  
  //       switch (targetIndex) {
  //         case 1:
  //           impresRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //           setTimeout(()=>{
  //             document.getElementById('ababa').scrollLeft = 0;
  //           },500)
  //           break;
  //         case 2:
  //           suprematismRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //           setTimeout(()=>{
  //             document.getElementById('ababa').scrollLeft = 0;
  //           },500)
  //           break;
  //         case 3:
  //           artnovRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //           setTimeout(()=>{
  //             document.getElementById('ababa').scrollLeft = 0;
  //           },500)
  //           break;
  //         case 4:
  //           classRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //           setTimeout(()=>{
  //             document.getElementById('ababa').scrollLeft = 0;
  //           },500)
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }
    
  // },100);

  // const navigate = useNavigate();

  // const awesmClick = ()=>{

  //   console.log('Clicked');
    
  //   switch (activeCardIndex) {
  //     case 1:
  //       setIsExpanded1(true);
  //       setTimeout(()=>{
  //         navigate('/impressionism');
  //       },300)
  //       break;
  //     case 2:
  //       setIsExpanded2(true);
  //       setTimeout(()=>{
  //         navigate('/suprematism');
  //       },300)
  //       break;
  //     case 3:
  //       setIsExpanded3(true);
  //       setTimeout(()=>{
  //         navigate('/artnouveau');
  //       },300)
  //       break;
  //     case 4:
  //       setIsExpanded4(true);
  //       setTimeout(()=>{
  //         navigate('/classicism');
  //       },300)
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <div
      id="cardsBlock"
      className="flex flex-col bg-cover bg-center h-svh justify-between overflow-hidden"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >

      <div className="absolute inset-0 z-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          className="w-full h-full object-cover"
          src={videoBackground} type="video/mp4; codecs='avc1.42E01E, mp4a.40.2'"
        />
      </div>
      
      <div className="z-10 flex justify-center fixed w-full">
        <img src={logo} alt="logo" className="w-3/5 mt-5" />
      </div>

      <div className="flex z-40 pb-10 hide-scroll-bar h-svh w-full relative top-6 scroll-container" ref={scrollContainerRef}>
        <div className="flex flex-nowrap items-center  overflow-x-hidden">

          <div className="">
            {/* <img src={central} alt="bombay saphire" className="card-container h-auto" /> */}
            <div className=" w-svw h-auto flex justify-center">
              <img src={central} alt="bombay saphire" className="card-container h-auto" />
            </div>
          </div>

          {/* <img src={central} alt="bombay saphire" className="card-container h-auto" /> */}
          <div className="" ref={impresRef}>
            <Card className="" data={data[0]} path='impressionism'/>
          </div>

          <div className="" ref={suprematismRef}>
            <Card data={data[1]} path='suprematism'/>
          </div>

          <div className="" ref={artnovRef}>
            <Card data={data[2]} path='artnouveau'/>
          </div>

          <div className="" ref={classRef}>
            <Card data={data[3]} path='classicism' onClick/>
          </div>

        </div>
      </div>

      {/* <div className="fixed opacity-0 h-screen bg-transparent overflow-y-auto z-[60]" id="ababa">
        <div className="w-[101%] h-full flex items-center justify-center">
          <div className="h-full flex justify-between">
            <div className="bg-blue-500 h-[100%] w-full">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
            <div className="bg-red-500 h-[100%] w-full">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
          </div>
        </div>
      </div> */}

      {!goDots ? 
          <div className="w-full fixed z-50 startbut justify-center flex">
            <img src={rightb} alt="" className=" w-14 h-14" onClick={()=>{
              setGodots(true);
              setActiveCardIndex(1);
              setrightButtActive(false);
              impresRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
              }}/>
          </div>
    :<></> }

      {/* <div className="w-full fixed z-50 startbut justify-center flex">
        <img src={rightb} className=" w-14 h-14" onClick={goMoveRight}/>
      </div> */}

      {goDots ? 
      <div className="flex justify-center z-50 fixed bottom-0 w-full items-center mb-4 space-x-4">

        {rightButtActive 
        ? <img src={leftb} alt="" className="w-14 h-full" onClick={goMoveLeft}/>
        : <div className="w-14 h-full"></div>
        }
        
        <IndicatorDots activeIndex={activeCardIndex}/>

        {leftButtActive 
        ? <img src={rightb} alt="" className=" w-14 h-full" onClick={goMoveRight}/>
        : <div className="w-14 h-full"></div>
        }

      </div>
      : <></>}
      

    </div>
  );
}

export default Home;

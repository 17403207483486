import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import impressionism_full from "../images/impres.png";
import back from "../images/back-button 1.png";
import exit from "../images/exit.png";
import scrolldown from "../images/arrow.gif";
import coctail from "../images/Impressionism glass.png";
import {motion} from "framer-motion";

function Impressionism() {
  // const coctailRef = useRef(null);
  const firstDivRef = useRef(null);

  const [buttonVisible, setButtonVisible] = useState(true);
  const [handledScroll, setHandled] = useState(true);  
 
  const [cocktailVisible, setCocktailVisible] = useState(false); 

  const handleClick = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleDownload = () => {
    // Replace 'image.jpg' with the actual URL or path to your image
    const imageUrl = 'Card impressionism.png';

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'card_bombaySaphire_impressionism.jpg'; // Set the desired file name

    // Append the link to the document body
    document.body.appendChild(link);

    // Simulating a click event on the link to trigger the download
    link.click();

    // Clean up by removing the temporary link from the document
    document.body.removeChild(link);
  };

  const blockRef = useRef(null);

  let onetime = true;

  const handleMainClick = (e) => {
    console.log('handled');

    // var target = e.nativeEvent.target;
    // console.log(document.getElementById('aboba').scrollHeight)
    
    // setTimeout(()=>{
    //   document.getElementById('aboba').scrollTop = 0;
    // },500);

    if(onetime){
      console.log('onetime started');
      onetime = false;
      setTimeout(()=>{
        onetime=true;
      },500);
    }
    
    if(handledScroll){
      setHandled(false);

      setTimeout(()=>{
        setHandled(true);
        console.log('refreshed');
      },2000);

      if(!cocktailVisible){
        setCocktailVisible(true);

      }else{
        setCocktailVisible(false);
      }
    }

  }

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   window.addEventListener('scroll', ()=>{console.log('aaaa')});
  // });

  return (
    <div>
      <div className="z-40 fixed">
        <div className="fixed top-5 left-5">
          {cocktailVisible ? (
            <button onClick={handleMainClick}>
              <img
                className="h-[30px]"
                src={back}
                alt="back-button-scroll"
              />
            </button>
          ) : (
            <Link to="/home">
              <img className="h-[30px]" src={back} alt="back-button" />
            </Link>
          )}
        </div>
        <div className="fixed top-5 right-5">
          <Link to="/home">
            <img className="h-[30px]" src={exit} alt="exit-button" />
          </Link>
        </div>
      </div>
{/* //////////////////////////////////////       */}
      <div
        ref={firstDivRef}
        className="bg-cover bg-center h-svh flex flex-col items-center justify-between "
        style={{ backgroundImage: `url(${impressionism_full})` }}
        // onClick={handleMainClick}
      >
        <div>
          <p className="text-center text-white font-bombay text-[36px] mt-14">
            IMPRESSIONISM
          </p>
        </div>

        <div className="flex justify-center">
          {buttonVisible && (
            <button>
              <img className="h-[30px] mb-3" src={scrolldown} alt="swipe-button" />
            </button>
          )}
        </div>
        {/* <div className="h-full opacity-45 bg-red-400 fixed flex overflow-scroll" onScroll={console.log}>
          <div className="sloder h-5"></div>
        </div> */}
        <div className="fixed opacity-0 h-screen bg-transparent overflow-y-auto" onScrollCapture={handleMainClick}  id="aboba">
          <div className="h-[110%] w-full flex items-center justify-center">
            <div className="h-full flex justify-between">
              <div className="bg-blue-500 h-[100%] w-full">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
              <div className="bg-red-500 h-[100%] w-full">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
            </div>
          </div>
        </div>
      </div>
{/* ////////////////////////////////////// */}
    {
      cocktailVisible &&

      <motion.div
      // ref={coctailRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: cocktailVisible ? 1 : 0 }}
      transition={{ duration: 1 }}
      className="w-screen h-svh bg-cyan-400 flex flex-col justify-between py-14 z-10 fixed top-0 left-0 pointer-events-none"
      // onClick={handleMainClick}
      >

        <p className="text-white text-m text-center font-sapphire">
          COCKTAIL
        </p>
        <div className="w-3/4 mx-auto flex flex-col items-center justify-between h-full mb-3">
          <p className="text-center text-white font-bombay text-3xl md:text-4xl mt-2">
            IMPRESSIVE <br /> BOMBAY & TONIC
          </p>
          <img src={coctail} className="recptimg" alt="coctail" />
          <p className="text-white text-m font-sapphire text-center mb-10">
            BOMBAY SAPPHIRE 50 ml<br />
            Premium tonic water 100 ml<br />
            2 lemon wedges<br />
            3 thyme or lemon thyme sprigs<br />
            Ice
          </p>
        </div>
        <div className="flex justify-center pointer-events-auto">
          <a
            onClick={handleDownload}
            className="text-white text-[24px] font-sapphire white border border-white font-medium rounded-full text-lg px-6 py-2.5 text-center mb-4"
          >
            SAVE
          </a>
        </div>
      </motion.div>
    }

    </div>
  );
}

export default Impressionism;
